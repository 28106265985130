import React, { ElementType, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const TitleSC = styled.h1`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const Title: FC<PropsWithChildren<{ as?: ElementType; className?: string }>> =
  function ({ as, children, className }) {
    return (
      <TitleSC as={as} className={className}>
        {children}
      </TitleSC>
    );
  };

export default Title;
