import { Link } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Footer: FC<{ pathname: string }> = function ({ pathname }) {
  const { t } = useTranslation();

  return (
    <footer className="mt-20 pt-3 bg-white-50 px-6 md:px-0">
      <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-around">
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.address.title')}
          </h3>
          <ul>
            <li>Lille</li>
            <li>North of France</li>
          </ul>
        </div>
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.categories.title')}
          </h3>
          <div className="flex space-x-6">
            <ul>
              <li>
                <Link to={t('footer.categories.values.meubles.pathname')}>
                  {t('footer.categories.values.meubles.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.tables.pathname')}>
                  {t('footer.categories.values.tables.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.luminaires.pathname')}>
                  {t('footer.categories.values.luminaires.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.cheminees.pathname')}>
                  {t('footer.categories.values.cheminees.label')}
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to={t('footer.categories.values.divers.pathname')}>
                  {t('footer.categories.values.divers.label')}
                </Link>
              </li>
              <li>
                <Link
                  to={t('footer.categories.values.decoration-murales.pathname')}
                >
                  {t('footer.categories.values.decoration-murales.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.sieges.pathname')}>
                  {t('footer.categories.values.sieges.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.categories.values.art-table.pathname')}>
                  {t('footer.categories.values.art-table.label')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.liens.title')}
          </h3>
          <div className="flex space-x-6">
            <ul>
              <li>
                <Link to={t('footer.liens.values.newsletter.pathname')}>
                  {t('footer.liens.values.newsletter.label')}
                </Link>
              </li>
              <li>
                <Link to={t('footer.liens.values.contact.pathname')}>
                  {t('footer.liens.values.contact.label')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-center md:text-left">
            {t('footer.contact.title')}
          </h3>
          <div className="flex space-x-6">
            <ul>
              <li className="label">{t('footer.contact.phone')}</li>
              <li>011 336 1237 5555</li>
            </ul>
            <ul>
              <li className="label">{t('footer.contact.email')}</li>
              <li>
                <a href="mailto:info@modern-eye-gallery.com">
                  info@modern-eye-gallery.com
                </a>
              </li>
              <li>{t('footer.contact.ouverture-sur-rdv')}</li>
            </ul>
          </div>
        </div>
      </div>
      <ul className="flex items-center justify-center text-xs mt-10">
        <li>
          Copyright © 2022 Modern-eye-gallery.com.{' '}
          {t('footer.copyright.pense-et-concu-par')}{' '}
          <a href="//www.innedit.fr">Innédit</a>.{' '}
          {t('footer.copyright.tous-droits-reserves')}.
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
