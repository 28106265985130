import { BreadcrumbType } from '@innedit/innedit-type';
import classnames from 'classnames';
import { Link } from 'gatsby';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ChevronSC = styled.span`
  :not(:first-child) {
    :before {
      content: '>';
      display: inline-block;
      margin-right: 0.75rem;
    }
    margin-left: 0.75rem;
  }
`;

const Breadcrumb: FC<{
  className?: string;
  items: BreadcrumbType[];
  title: string;
}> = ({ className, items, title }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames(
        className,
        'text-asphalt-500 text-sm',
        'w-full max-w-screen-2xl',
        'mt-10 md:mt-0 mx-0 sm:mx-auto px-12 mb-3 md:mb-6',
        'flex whitespace-nowrap items-center justify-center',
      )}
    >
      {items.map(item => (
        <ChevronSC
          key={objectHash({ ...item, type: 'breadcrumb' })}
          as={Link}
          to={item.to}
        >
          {t(item.value)}
        </ChevronSC>
      ))}
      <ChevronSC className="inline-block">{t(title)}</ChevronSC>
    </div>
  );
};

export default Breadcrumb;
