import { PageNode } from '@innedit/innedit-type';
import { Link } from 'gatsby';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import styled from 'styled-components';

const SubMenuSC = styled.ul`
  display: block;
  text-align: center;
  list-style: none;
  padding: 0;

  li {
    display: inline-block;

    margin-right: 0.75rem;
    margin-bottom: 0.75rem;

    a {
      display: block;
      background: #f6f6f6;
      padding: 0.375rem 0.75rem;
      border-radius: 4px;
      text-decoration: none;
      color: #333;
      transition: color 0.15s ease;

      &:hover {
        color: #757575;
      }

      &.actif {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
`;

const SubMenu: FC<{
  actif?: boolean;
  className?: string;
  datas: PageNode[];
}> = ({ actif, className, datas }) => (
  <SubMenuSC className={className}>
    {datas &&
      datas.length > 0 &&
      datas.map((data, key) => (
        <li key={objectHash(data)}>
          <Link className={actif ? 'actif' : ''} to={`/${data.pathname}`}>
            {data.altNavTitle || data.name}
          </Link>
        </li>
      ))}
  </SubMenuSC>
);

export default SubMenu;
