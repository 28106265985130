import { BreadcrumbType, PageNode } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Content from '../Content';
import SubMenu from '../SubMenu';
import Title from '../Title';
import BannerTop from './BannerTop';
import Breadcrumb from './Breadcrumb';
import Footer from './Footer';
import Header from './Header/index';

interface LayoutProps {
  breadcrumbs?: BreadcrumbType[];
  breadcrumbsTitle?: string;
  carousel?: any[];
  head?: {
    meta?: any;
    title?: string;
  };
  loading?: boolean;
  pathname: string;
  showTitle?: boolean;
  subPages?: PageNode[];
  title?: string;
  titleClassName?: string;
}

const Layout: FC<PropsWithChildren<LayoutProps>> = function ({
  breadcrumbs,
  breadcrumbsTitle,
  carousel,
  children,
  head,
  loading,
  pathname,
  showTitle,
  subPages,
  title,
  titleClassName = 'my-20 mx-6 md:mx-auto text-3xl md:text-5xl text-center',
}) {
  const { t } = useTranslation();

  const pageTitle =
    head && head.title ? t(head.title) : (title && t(title)) || '';

  return (
    <div className="flex flex-col md:min-h-screen">
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={head && head.meta ? head.meta : []}
        title={`${pageTitle ? `${pageTitle} - ` : ''}${
          process.env.GATSBY_SITE_NAME
        }`}
      />

      <BannerTop />
      <Header
        className={classnames('z-0', {
          'h-v-2/3': carousel,
        })}
        pathname={pathname}
      />
      <div
        className={classnames('flex flex-col flex-1', {
          'md:mt-10': !showTitle || !title,
        })}
      >
        {showTitle && title && (
          <Title className={titleClassName}>{t(title)}</Title>
        )}
        {breadcrumbs && breadcrumbsTitle && (
          <Breadcrumb items={breadcrumbs} title={breadcrumbsTitle} />
        )}
        {subPages && <SubMenu datas={subPages} />}
        {loading ? <Content>{t('loading')}</Content> : children}
      </div>
      <Footer pathname={pathname} />
    </div>
  );
};

export default Layout;
