import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import IconLoading from '../../icons/Loading';
import screens from '../../styles/screens';
import spacing from '../../styles/spacing';

type SizeProps = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const getContentSizeStyles = (size?: SizeProps) => {
  switch (size) {
    case 'sm':
      return css``;
    case 'md':
      return css`
        max-width: ${screens.md};
      `;
    case 'lg':
      return css`
        max-width: ${screens.lg};
      `;
    case 'xl':
      return css`
        max-width: ${screens.xl};
      `;
    case '2xl':
      return css`
        max-width: ${screens['2xl']};
      `;
    default:
      return css``;
  }
};

const ContentSC = styled.div<{ size?: SizeProps }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${spacing[6]};

  @media only screen and (min-width: ${screens.sm}) {
    padding: 0 ${spacing[12]};
  }

  ${props => getContentSizeStyles(props.size)};

  .public-DraftStyleDefault-block {
    margin-bottom: 1.45rem;
  }
`;

// const Pending = styled.div`
//   background: rgba(0, 0, 0, 0.5) url(${pending}) no-repeat center center;
//   position: fixed;
//   z-index: 1000;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// `;

const Content: FC<
  PropsWithChildren<{
    className?: string;
    isPending?: boolean;
    loading?: boolean;
    size?: SizeProps;
  }>
> = function ({ className, children, isPending, loading, size }) {
  return (
    <>
      {loading ? (
        <div>Chargement en cours</div>
      ) : (
        <ContentSC className={className} size={size}>
          {children}
        </ContentSC>
      )}
      {isPending && <IconLoading />}
    </>
  );
};

export default Content;
