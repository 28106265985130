import classnames from 'classnames';
import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';

import screens from '../../../styles/screens';

const HamburgerSC = styled.a`
  position: relative;
  height: 48px;
  width: 48px;
  display: none;

  @media only screen and (max-width: ${screens.lg}) {
    display: block;
    position: absolute;
    left: 0;
  }

  .hamburger-top,
  .hamburger-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;

    transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: rotate(0);

    span {
      display: block;
      width: 17px;
      height: 1px;
      background: #000;
      position: absolute;
      left: 16px;
      z-index: 1;
    }
  }

  .hamburger-top span {
    top: 23px;
    transform: translateY(-3px);
  }

  .hamburger-bottom span {
    bottom: 23px;
    transform: translateY(3px);
  }

  &.is-open {
    .hamburger-top {
      transform: rotate(45deg);
      span {
        transform: translateY(0px);
        top: 23px;
      }
    }

    .hamburger-bottom {
      transform: rotate(-45deg);

      span {
        transform: translateY(0px);
        bottom: 23px;
      }
    }
  }
`;

const Hamburger: FC<{
  className?: string;
  handleToggleMenu: (event: SyntheticEvent<HTMLElement>) => void;
  openMenu: boolean;
}> = ({ className, handleToggleMenu, openMenu }) => (
  <HamburgerSC
    className={classnames(className, { 'is-open': openMenu })}
    onClick={handleToggleMenu}
  >
    <span className="hamburger-top">
      <span />
    </span>
    <span className="hamburger-bottom">
      <span />
    </span>
  </HamburgerSC>
);

export default Hamburger;
